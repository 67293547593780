import React from "react";
import { useNavigate } from "react-router-dom";
import "./Card_items.css";
import Try_Carousel from './Try_carousel';
export default function CardItems(props) {
  const navigate = useNavigate();

  const handleNavigate = (service) => {
    navigate(`/services#${service}`);
  };
  const scrollToTop = () => {
    // Ensure scrolling happens for both window and the html/body elements
    window.scrollTo({ top: 0, behavior: "smooth" });
    document.documentElement.scrollTop = 0;  // For Chrome, Firefox, IE, and Opera
    document.body.scrollTop = 0;             // For Safari
  };
  


  const handleCardClick = (service) => {
    setTimeout(() => {
      scrollToTop();
    }, 200);
    handleNavigate(service);
  };

  return (
    <>
      <div className="heading" style={{ marginTop: "20vh" }}>
        <button
          className="btn-sm move-top-btn"
          onClick={scrollToTop}
        >
          Scroll Top
        </button>
        <br />
        <br />
        {props.mode === "dark"? (
          <Try_Carousel />
        ) : (
          <div className="gif">
            <img
              src={`${process.env.PUBLIC_URL}/gifs/9.gif`}
              style={{ width: "400px", marginLeft: "-8vw" }}
              alt="pic"
              className="img-dark img-fluid first"
            />
            <img
              src={`${process.env.PUBLIC_URL}/gifs/2.gif`}
              style={{ width: "400px", marginLeft: "3.7vw" }}
              alt="pic"
              className="img-dark img-fluid"
            />
            <img
              src={`${process.env.PUBLIC_URL}/gifs/3.gif`}
              style={{ width: "400px", marginLeft: "3.7vw" }}
              alt="pic"
              className="img-dark img-fluid"
            />
          </div>
        )}
        <br />
        <br />
        <h1>Services</h1>
        <br />
      </div>
      <div className="full_Card_item_container">
      <div className="container_card_item">
        <div
          className="card_item"
          style={{ width: "21.5rem", height: "203px" }}
          onClick={() => handleCardClick("app-development")}
        >
          <img
            src={`${process.env.PUBLIC_URL}/gifs/mb_app.gif`}
            alt="App Development"
            className="card-img-top"
            style={{ width: "420px", height: "200px" }}
          />
        </div>
        <div
          className="card_item"
          style={{ width: "21.5rem", height: "203px" }}
          onClick={() => handleCardClick("cloud-services")}
        >
          <img
            src={`${process.env.PUBLIC_URL}/gifs/cloud_services.gif`}
            className="card-img-top"
            alt="None"
            style={{ width: "420px", height: "200px" }}
          />
        </div>
        <div
          className="card_item"
          style={{ width: "22.5rem", height: "203px" }}
          onClick={() => handleCardClick("consulting-services")}
        >
          <img
            src={`${process.env.PUBLIC_URL}/gifs/cs.gif`}
            className="card-img-top"
            alt="None"
            style={{ width: "100%", height: "100%", objectFit: "fill" }}
          />
        </div>
      </div>
      <div className="container_card_item">
        <div
          className="card_item"
          style={{ width: "21.5rem", height: "203px" }}
          onClick={() => handleCardClick("devops-sre")}
        >
          <img
            src={`${process.env.PUBLIC_URL}/gifs/devops.gif`}
            className="card-img-top"
            alt="None"
            style={{ width: "420px", height: "200px" }}
          />
        </div>
        <div
          className="card_item"
          style={{ width: "21.5rem", height: "203px" }}
          onClick={() => handleCardClick("startup-services")}
        >
          <img
            src={`${process.env.PUBLIC_URL}/gifs/startup.gif`}
            className="card-img-top"
            alt="None"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </div>
        <div
          className="card_item"
          style={{ width: "21.5rem", height: "203px" }}
          onClick={() => handleCardClick("web-development")}
        >
          <img
            src={`${process.env.PUBLIC_URL}/gifs/web_dev.gif`}
            className="card-img-top"
            alt="None"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </div>
      </div>
      <div className="container_card_item">
        <div
          className="card_item"
          style={{ width: "21.5rem", height: "203px" }}
          onClick={() => handleCardClick("internships")}
        >
          <img
            src={`${process.env.PUBLIC_URL}/gifs/intern.gif`}
            className="card-img-top"
            alt="None"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </div>
        <div
          className="card_item"
          style={{ width: "21.5rem", height: "203px" }}
          onClick={() => handleCardClick("gen-ai")}
        >
          <img
            src={`${process.env.PUBLIC_URL}/gifs/gen_ai.gif`}
            className="card-img-top"
            alt="None"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </div>
        <div
          className="card_item"
          style={{ width: "21.5rem", height: "203px" }}
          onClick={() => handleCardClick("aiml")}
        >
          <img
            src={`${process.env.PUBLIC_URL}/gifs/aiml.gif`}
            className="card-img-top"
            alt="None"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </div>
      </div>
      <div className="container_card_item">
        <div
          className="card_item"
          style={{ width: "21.5rem", height: "203px"}}
          onClick={() => handleCardClick("maintain")}
        >
          <img
            src={`${process.env.PUBLIC_URL}/gifs/maintain.gif`}
            className="card-img-top"
            alt="None"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </div>
        <div
          className="card_item"
          style={{ width: "21.5rem", height: "203px" }}
          onClick={() => handleCardClick("issue-res")}
        >
          <img
            src={`${process.env.PUBLIC_URL}/gifs/Solution.gif`}
            className="card-img-top"
            alt="None"
            style={{ width: "100%", height: "100%", objectFit: "cover"}}
          />
        </div>
        <div
          className="card_item"
          style={{ width: "21.5rem", height: "213px" }}
          onClick={() => handleCardClick("iot")}
        >
          <img
            src={`${process.env.PUBLIC_URL}/gifs/iot.gif`}
            className="card-img-top"
            alt="None"
            style={{ width: "100%", height: "100%", objectFit:"fill"  ,marginTop:'0.5vh' }}
          />
        </div>
        </div>
        {/* <div className="card_item_theme">
          <img src={`${process.env.PUBLIC_URL}/Services/theme.jpg`} className="card-img-top" alt="None" style={{height: '400px' }} />
        </div> */}
      </div>
    </>
  );
}
