import { React, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "./Navbar.css";

export default function Navbar(props) {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location.pathname]);

  const scrollToTop = () => {
    // Ensure scrolling happens for both window and the html/body elements
    window.scrollTo({ top: 0, behavior: "smooth" });
    document.documentElement.scrollTop = 0;  // For Chrome, Firefox, IE, and Opera
    document.body.scrollTop = 0;             // For Safari
  };
  

  return (
    <nav className="navbar navbar-expand-lg fixed-top" id="top">
      <div className="container-fluid">
        <Link className="navbar-brand" to="/" onClick={scrollToTop}>
          <img
            src={`${process.env.PUBLIC_URL}/p_full.png`}
            alt="logo"
            className="logo"
          />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link className="nav-link" aria-current="page" to="/" onClick={scrollToTop}>Home</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" aria-current="page" to="/about">About Us</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" aria-current="page" to="/blog" onClick={scrollToTop}>Blogs</Link>
            </li>
            <li className="nav-item contact">
              <Link 
                className="contact-link nav-link" 
                to="#footer" 
                onClick={(e) => {
                  e.preventDefault(); 
                  const footerElement = document.getElementById("footer-section");
                  if (footerElement) {
                    footerElement.scrollIntoView({ behavior: "smooth" });
                  }
                }}
              >
                Contact Us
              </Link>
            </li>
          </ul>
          <form className="form-check form-switch text-secondary">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckDefault"
              onClick={props.togglemode}
            />
            <label className="form-check-label mx-3" htmlFor="flexSwitchCheckDefault">
              Dark
              <br />
              Mode
            </label>
          </form>
        </div>
      </div>
    </nav>
  );
}
